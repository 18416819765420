.banner {
  display: flex;
  background-color: var(--color-navy);
  padding: var(--spacing-3);

  a {
    text-align: center;
    margin: auto;
    text-decoration: none;
    color: white;

    .bold {
      font-weight: 700;
    }

    &:hover, &:focus{
      text-decoration: underline;
    }
  }
}

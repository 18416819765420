@import 'styles/variables.scss';

.container {
  border-radius: var(--spacing-1);
  color: var(--textColor-primary);
  font-family: var(--fontFamily-netto);
  font-size: var(--text-2xl-size);
  padding: var(--spacing-1) var(--spacing-2);
  width: max-content;

  @media (min-width: $screens-lg) {
    border-width: 0;
    font-size: var(--text-base-size);
    padding: 0;
    width: auto;
    position: relative;
  }

  span.active {
    position: relative;

    &::before {
      content: '';
      background-color: #ffca58;
      bottom: 0;
      height: 3px;
      width: 100%;
      position: absolute;
      transform: scaleX(1);

      @media (min-width: $screens-lg) {
        content: '';
        background-color: #ffca58;
        bottom: 0;
        height: 3px;
        width: 100%;
        left: 0;
        position: absolute;
        transform: scaleX(1);
      }
    }
  }
}

.select {
  background-color: var(--color-white);
  overflow: hidden;
  box-shadow: 0 4px 4px -4px var(--color-silver);

  @media (min-width: $screens-lg) {
    box-shadow: 0 4px 20px 4px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    position: absolute;
    top: 0;
    width: max-content;
  }
}

.selectContainer {
  padding-top: var(--spacing-4);
  position: absolute;
  z-index: 10;
  left: 0;
  width: 100vw;
  top: 34px;

  @media (min-width: $screens-lg) {
    bottom: 0;
    top: var(--spacing-8);
    width: var(--spacing-50);
    min-width: max-content;
  }
}

a.animate {
  &::before {
    content: ' ';
    background-color: var(--color-primary);
    bottom: var(--spacing-1);
    height: 1px;
    left: 0;
    margin-left: var(--spacing-0-5);
    position: absolute;
    right: 0;
    transform: scaleX(0);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    visibility: hidden;
  }

  &:hover::before {
    transform: scaleX(1);
    visibility: visible;
  }
}

.icon {
  transition: transform 0.5s;

  &.iconOpen {
    transform: rotate(-180deg);
  }
}

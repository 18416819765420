.mainNavigation {
  ul {
    gap: 29px;

    li {
      a {
        &.animate {
          &::before {
            content: '';
            background-color: var(--color-primary);
            bottom: var(--spacing-1);
            height: 1px;
            left: 0;
            margin-left: var(--spacing-0-5);
            position: absolute;
            right: 0;
            transform: scaleX(0);
            transition-property: all;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 300ms;
            visibility: hidden;
          }

          &:hover::before {
            transform: scaleX(1);
            visibility: visible;
          }
        }

        &.active {
          &::before {
            content: '';
            background-color: #ffca58;
            bottom: -4px;
            height: 3px;
            left: 0;
            right: 0;
            position: absolute;
            transform: scaleX(1);
          }
        }
      }
    }
  }
}

.reviewScoreBox {
  background-color: #3dae63;
  border-radius: 5px;
  color: #fff;
  margin-left: 9px;
  padding: 0 6px;
}

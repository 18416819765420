@import 'styles/variables.scss';

.extended {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 29px;
}

.header {
  background-color: var(--color-white);
  z-index: 30;
}

.inner {
  display: flex;
  align-items: center;
  height: var(--spacing-20);
  justify-content: center;
  margin: 0 auto;
  max-width: $screens-xl;

  @media (min-width: $screens-md) {
    padding-block: var(--spacing-5);
    padding-inline: var(--spacing-4);
  }

  @media (min-width: $screens-lg) {
    justify-content: space-between;
  }
}

.logoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 190px;
  height: 60px;

  img {
    object-fit: contain;
  }

  .logo {
    order: 9999;
    padding-top: var(--spacing-1);

    @media (min-width: $screens-lg) {
      order: -9999;
    }

    svg {
      height: var(--spacing-8);
      width: var(--spacing-44);
    }
  }
}

.mobileCurrency {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-2);
  padding-block: var(--spacing-2);
  position: relative;

  @media (min-width: $screens-lg) {
    justify-content: flex-start;
    margin-bottom: 0;
    padding-block: 0;
  }
}

.mobileLanguage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-4);
  padding-block: var(--spacing-2);
  position: relative;

  @media (min-width: $screens-lg) {
    justify-content: flex-start;
    margin-top: 0;
    padding-block: 0;
    padding-right: var(--spacing-6);
  }
}

.mobileMenuButton {
  left: 0;
  margin-left: var(--spacing-4);
  padding-left: 0;
  position: absolute;

  @media (min-width: $screens-lg) {
    display: none;
  }

  svg {
    height: var(--spacing-5);
    width: var(--spacing-6);
  }
}

.mobileWrapperHidden {
  display: none;
}

.mobileWrapper {
  display: flex;
  flex-direction: row;
  background-color: var(--color-white);
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  height: 50px;

  @media (min-width: $screens-lg) {
    display: none;
  }

  & nav ul li:last-child {
    padding-right: var(--spacing-2-5);
  }

  &Slider {
    padding-left: var(--spacing-4);
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    scroll-padding-inline: var(--spacing-4);
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 5%;
    height: 100%;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 90%
    );

    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 5%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 90%
    );
  }
}

.search {
  display: flex;
  align-items: center;
  color: var(--textColor-primary);
  cursor: pointer;
  font-family: var(--fontFamily-netto);
  position: absolute;
  right: 0;
  text-transform: uppercase;
  margin-right: 15px;

  @media (min-width: $screens-lg) {
    margin-right: var(--spacing-6);
    position: relative;
  }
}

.searchSvg {
  width: var(--spacing-6);

  @media (min-width: $screens-lg) {
    width: var(--spacing-6);
  }
}

.searchLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.searchText {
  display: none;
  font-weight: 700;
  padding-block: var(--spacing-2);

  @media (min-width: $screens-lg) {
    display: block;
  }
}

.wrapper {
  background-color: var(--color-white);

  @media (min-width: $screens-lg) {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
      0 2px 4px -2px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  position: relative;
  z-index: 40;
}

.languageWrapper {
  @media (max-width: $screens-lg) {
    & div:nth-child(1) div:nth-child(1) {
      & span {
        width: 2ch;
        overflow: hidden;
      }

      & svg {
        display: none;
      }
    }
  }
}

.background {
  background-color: var(--color-tertiary-grey);
}

.container {
  display: flex;
}

.hover:hover {
  background-color: var(--color-lightGrey);
}

.icon {
  height: var(--spacing-4);
}

.item {
  display: flex;
  align-items: center;
  border-radius: 0.375rem;
  height: var(--spacing-8);
  justify-content: center;
  margin-right: var(--spacing-2);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  width: var(--spacing-8);
}

.rounded {
  border-radius: 9999px;
}
